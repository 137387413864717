// 下载pdf
export function downloadPdf(url, fileName) {

  fetch(url).then((res) => {

    res.blob().then((blob) => {

      const blobUrl = window.URL.createObjectURL(blob);

      const filename = fileName + '.pdf';

      const a = document.createElement('a');

      a.href = blobUrl;

      a.download = filename;

      a.click();

      window.URL.revokeObjectURL(blobUrl);

    });

  });

}
